export default [
  {
    path: '/users',
    name: 'users-index',
    component: () => import('@/views/users/List.vue'),
    meta: {
      pageTitle: 'admin.menu.users',
      breadcrumb: [
        {
          text: 'admin.menu.users_list',
          active: true,
        },
      ],
      resource: 'users',
      action: 'index',
    },
  },
  {
    path: '/users/create',
    name: 'users-store',
    component: () => import('@/views/users/Create.vue'),
    meta: {
      pageTitle: 'admin.menu.users',
      breadcrumb: [
        {
          text: 'admin.menu.users',
          to: { name: 'users-index' },
        },
        {
          text: 'admin.buttons.add',
          active: true,
        },
      ],
      resource: 'users',
      action: 'store',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-update',
    component: () => import('@/views/users/Edit.vue'),
    meta: {
      pageTitle: 'admin.menu.users',
      breadcrumb: [
        {
          text: 'admin.menu.users',
          to: { name: 'users-index' },
        },
        {
          text: 'admin.buttons.edit',
          active: true,
        },
      ],
      resource: 'users',
      action: 'update',
    },
  },

  {
    path: '/admin/transactions',
    name: 'transactions-index',
    component: () => import('@/views/transactions/List.vue'),
    meta: {
      pageTitle: 'admin.menu.transactions',
      breadcrumb: [
        {
          text: 'admin.menu.transactions',
          active: true,
        },
      ],
      resource: 'transactions',
      action: 'index',
    },
  },
]
