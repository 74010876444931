export default [
  {
    path: '/fm',
    name: 'file-manager',
    component: () => import('@/views/system/fm/List'),
    meta: {
      pageTitle: 'admin.menu.file-manager',
      breadcrumb: [
        {
          text: 'admin.menu.system',
        },
        {
          text: 'admin.menu.file-manager',
          active: true,
        },
      ],
      resource: 'settings',
      action: 'index',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/system/settings/List'),
    meta: {
      pageTitle: 'admin.menu.settings',
      breadcrumb: [
        {
          text: 'admin.menu.system',
        },
        {
          text: 'admin.menu.settings',
          active: true,
        },
      ],
      resource: 'settings',
      action: 'index',
    },
  },
  {
    path: '/languages',
    name: 'languages',
    component: () => import('@/views/system/languages/List'),
    meta: {
      pageTitle: 'admin.menu.languages',
      breadcrumb: [
        {
          text: 'admin.menu.system',
        },
        {
          text: 'admin.menu.languages',
          active: true,
        },
      ],
      resource: 'languages',
      action: 'index',
    },
  },
  {
    path: '/translations',
    name: 'translations',
    component: () => import('@/views/system/translations/List'),
    meta: {
      pageTitle: 'admin.menu.translations',
      breadcrumb: [
        {
          text: 'admin.menu.system',
        },
        {
          text: 'admin.menu.translations',
          active: true,
        },
      ],
      resource: 'translations',
      action: 'index',
    },
  },

  {
    path: '/currencies',
    name: 'currencies-index',
    component: () => import('@/views/system/currencies/List.vue'),
    meta: {
      pageTitle: 'admin.menu.currencies',
      breadcrumb: [
        {
          text: 'admin.menu.currencies',
          active: true,
        },
      ],
      resource: 'currencies',
      action: 'index',
    },
  },
  {
    path: '/currencies/create',
    name: 'currencies-store',
    component: () => import('@/views/system/currencies/Create.vue'),
    meta: {
      pageTitle: 'admin.menu.currencies',
      breadcrumb: [
        {
          text: 'admin.menu.currencies',
          to: { name: 'currencies-index' },
        },
        {
          text: 'admin.buttons.add',
          active: true,
        },
      ],
      resource: 'currencies',
      action: 'store',
    },
  },
  {
    path: '/currencies/edit/:id',
    name: 'currencies-update',
    component: () => import('@/views/system/currencies/Edit.vue'),
    meta: {
      pageTitle: 'admin.menu.currencies',
      breadcrumb: [
        {
          text: 'admin.menu.currencies',
          to: { name: 'currencies-index' },
        },
        {
          text: 'admin.buttons.edit',
          active: true,
        },
      ],
      resource: 'currencies',
      action: 'update',
    },
  },

  {
    path: '/roles',
    name: 'roles-index',
    component: () => import('@/views/system/roles/List.vue'),
    meta: {
      pageTitle: 'admin.menu.roles',
      breadcrumb: [
        {
          text: 'admin.menu.roles',
          active: true,
        },
      ],
      resource: 'roles',
      action: 'index',
    },
  },
  {
    path: '/roles/create',
    name: 'roles-store',
    component: () => import('@/views/system/roles/Create.vue'),
    meta: {
      pageTitle: 'admin.menu.roles',
      breadcrumb: [
        {
          text: 'admin.menu.roles',
          to: { name: 'roles-index' },
        },
        {
          text: 'admin.buttons.add',
          active: true,
        },
      ],
      resource: 'roles',
      action: 'store',
    },
  },
  {
    path: '/roles/edit/:id',
    name: 'roles-update',
    component: () => import('@/views/system/roles/Edit.vue'),
    meta: {
      pageTitle: 'admin.menu.roles',
      breadcrumb: [
        {
          text: 'admin.menu.roles',
          to: { name: 'roles-index' },
        },
        {
          text: 'admin.buttons.edit',
          active: true,
        },
      ],
      resource: 'roles',
      action: 'update',
    },
  },

  {
    path: '/admin/notification_templates',
    name: 'notification_templates-index',
    component: () => import('@/views/system/notification_templates/List.vue'),
    meta: {
      pageTitle: 'admin.menu.notification_templates',
      breadcrumb: [
        {
          text: 'admin.menu.notification_templates',
          active: true,
        },
      ],
      resource: 'notifications',
      action: 'index',
    },
  },
  {
    path: '/admin/notification_templates/create',
    name: 'notification_templates-store',
    component: () => import('@/views/system/notification_templates/Create.vue'),
    meta: {
      pageTitle: 'admin.menu.notification_templates',
      breadcrumb: [
        {
          text: 'admin.menu.notification_templates',
          to: { name: 'notification_templates-index' },
        },
        {
          text: 'admin.buttons.add',
          active: true,
        },
      ],
      resource: 'notifications',
      action: 'update',
    },
  },
  {
    path: '/admin/notification_templates/edit/:id',
    name: 'notification_templates-update',
    component: () => import('@/views/system/notification_templates/Edit.vue'),
    meta: {
      pageTitle: 'admin.menu.notification_templates',
      breadcrumb: [
        {
          text: 'admin.menu.notification_templates',
          to: { name: 'notification_templates-index' },
        },
        {
          text: 'admin.buttons.edit',
          active: true,
        },
      ],
      resource: 'notifications',
      action: 'update',
    },
  },
]
